import React from 'react';
import { Helmet } from 'react-helmet';

import 'styles/pages/ewz/styles.scss';

const Ewz = () => {
  return (
    <div className="scr-ewz-wrap">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <header>
        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-header.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '105px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
      </header>
      <main>
        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-hero.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '586px',
            backgroundPosition: 'center',
            marginBottom: '5%',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-columns.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '350px',
            backgroundPosition: 'center',
            marginBottom: '5%',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-banner.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '570px',
            backgroundPosition: 'center',
            marginBottom: '5%',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-wide-text.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '1190px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        <iframe
          title="EWZ survey"
          width={'100%'}
          height={'600px'}
          src="https://go.screver.com/ewz/65114f467c0f7c50d4f94443"
        ></iframe>
      </main>

      <footer>
        <div
          style={{
            backgroundImage:
              'url(https://wp.screver.com/wp-content/uploads/2023/09/ewz-footer.png)',
            backgroundSize: 'auto',
            objectFit: 'cover',
            height: '490px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
      </footer>

      <div className="scr-ewz-btn"></div>
    </div>
  );
};

export default Ewz;
